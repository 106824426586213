<template>
  <form>
    <div class="row">
      <div class="col">
        <base-input v-model="first_name"
          :placeholder="getTrans('messages.first_name')"
          :label="getTrans('messages.first_name')"
        ></base-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input v-model="last_name"
          :placeholder="getTrans('messages.last_name')"
          :label="getTrans('messages.last_name')"
        ></base-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input v-model="address"
          :placeholder="getTrans('messages.address')"
          :label="getTrans('messages.address')"
        ></base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <base-input v-model="zip"
          :placeholder="getTrans('messages.zip')"
          :label="getTrans('messages.zip')"></base-input>
      </div>
      <div class="col-6">
        <base-input v-model="city"
          :placeholder="getTrans('messages.city')"
          :label="getTrans('messages.city')"></base-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input :label="getTrans('messages.country')">
          <el-select
            v-model="country"
            filterable
            :placeholder="getTrans('messages.country')"
          >
            <el-option
              v-for="country in getCountries"
              :key="country.id"
              :label="country.name"
              :value="country.id"
              id="currentcountry"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :placeholder="getTrans('messages.bank_vat')"
          :label="getTrans('messages.bank_vat')"
        ></base-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-button @click="save">{{getTrans('messages.save')}}</base-button>
      </div>
    </div>
  </form>
</template>
<script>
import {ElOption, ElSelect} from 'element-plus'
import {mapGetters} from 'vuex'
export default {
  components: {
    ElOption,
    ElSelect,
  },
  emits: ['save'],
  props: {
    user: Object,
    getError: Function
  },
  data() {
    return {
      first_name:'',
      last_name:'',
      address:'',
      zip:'',
      city:'',
      country:'',
      vat:'',
    };
  },
  computed: {
    ...mapGetters('Country', ['getCountries']),
  },
  methods:{
    save() {
      let user = {};
      user.bank_name = this.first_name + ' ' + this.last_name;
      user.bank_address1 = this.address;
      user.bank_zip = this.zip;
      user.bank_city = this.city;
      user.bank_country = this.country ? this.$store.state.countries[this.country.id].name : '';
      user.bank_vat = this.vat;
      this.$emit('save', user);
    }
  },
  mounted() {
    if (this.user) {
      if(this.user.bank_name) {
          let name = this.user.bank_name.split(' ', 1);
          this.first_name = name[0];
          this.last_name = this.user.bank_name.substr(name[0].length + 1);
      }
      if(this.user.bank_address1)this.address = this.user.bank_address1;
      if(this.user.bank_zip)this.zip = this.user.bank_zip;
      if(this.user.bank_city)this.city = this.user.bank_city;
      if(this.user.bank_country)this.country.id = this.$store.state.countries.Array.filter(c => c.name === this.user.bank_country)[0].id;
      if(this.user.bank_vat)this.vat = this.user.bank_vat;
    }
    
  }
};
</script>
